import React, { lazy, Suspense } from 'react';

const LazyDiscovery = lazy(() => import('./discovery'));

const Discovery = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyDiscovery {...props} />
  </Suspense>
);

export default Discovery;
