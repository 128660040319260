import React, { lazy, Suspense, useContext } from 'react';
import { NavigationContext } from '../../hooks/navigation_provider';

const LazyNavigator = lazy(() => import('./navigation'));

const Navigation = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => {
  const nav = useContext(NavigationContext);

  return (
    <Suspense fallback={null}>
      <LazyNavigator currentTab={nav.tab} {...props} />
    </Suspense>
  )
};

export default Navigation;
