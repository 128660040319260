import { useQuery } from '@tanstack/react-query';
import { createContext, useState, ReactNode } from "react";
import { CredentialResponse } from "@react-oauth/google";
import { jwtDecode } from 'jwt-decode';
import axios from "axios";
import Cookies from "js-cookie";


interface LoginData {
  firstName: string;
  lastName?: string;
  email: string;
  password: string;
}

interface GoogleJwtPayload {
  // Standard  JWT claims
  iss: string;
  sub: string;
  aud: string[] | string;
  exp: number;
  nbf: number;
  iat: number;
  jti: string;
  // Non-standard JWT claims
  azp?: string;
  hd?: string;
  email?: string;
  email_verified?: boolean;
  name?: string;
  picture?: string;
  given_name?: string;
  family_name?: string;
  locale?: string;
}

interface TokenVerificationResponse {
  valid: boolean;
  idinfo: GoogleJwtPayload
}

interface AuthContextType {
  token: string;
  claims: GoogleJwtPayload | undefined;
  loginSSO: (response: CredentialResponse) => void;
  loginAction: (data: LoginData) => void;
  logOut: () => void;
  isValid: () => boolean;
}

const transformString = (input: string, foo = 'monkey') => {
  let shift = 17;
  return input
    .split('')
    .map(char => {
      const code = char.charCodeAt(0);
      if (code >= 65 && code <= 90) { // Uppercase letters
        return String.fromCharCode(((code - 65 + (foo == 'monkey' ? shift : 26 - shift)) % 26) + 65);
      } else if (code >= 97 && code <= 122) { // Lowercase letters
        return String.fromCharCode(((code - 97 + (foo == 'teapot' ? shift : 26 - shift)) % 26) + 97);
      } else {
        return char; // Non-alphabetic characters remain unchanged
      }
    }).join('');
}

const getClaims = () => {
  try {
    return jwtDecode<GoogleJwtPayload | undefined>(Cookies.get("_auth") || "")
  } catch {
    return undefined
  }
}

const APIEndpoint: string = process.env.NODE_ENV === 'production' ? `https://${process.env.REACT_APP_HOST}/api` : 'http://localhost:3000/api'

const defaultAuthState: AuthContextType = {
  token: Cookies.get("_auth") || "",
  claims: getClaims(),
  loginSSO: (response: CredentialResponse) => { },
  loginAction: (data: LoginData) => { },
  logOut: () => { },
  isValid: (): boolean => { return false },
}

const AuthContext = createContext(defaultAuthState);

const AuthProvider = ({ children }: { children?: ReactNode }) => {
  console.debug("Auth provider is initializing")
  const [claims, setClaims] = useState<GoogleJwtPayload | undefined>(getClaims());
  const [token, setToken] = useState(Cookies.get("_auth") || "");

  const socialAuthTokenVerify = async (token: string) => {
    return await axios.post<TokenVerificationResponse>(
      `${APIEndpoint}/oauth/google`,
      { credentials: token }
    )
  }

  const { data: verifyAuthToken } = useQuery({
    queryKey: ["socialAuthTokenVerify"],
    queryFn: () => socialAuthTokenVerify(token),
    staleTime: 1800 * 1000 // measured in milliseconds
  })

  const loginSSO = (response: CredentialResponse) => {
    const claims = jwtDecode<GoogleJwtPayload | undefined>(response.credential || "");
    console.debug(claims);
    setClaims(claims);
    setToken(response.credential || "");

    // Expiry is measured in days from cookie creation
    Cookies.set("_auth", response.credential || "", { secure: true, sameSite: "strict", expires: 3 });
    return;
  }

  const loginAction = async (data: LoginData) => {
    try {
      // TODO: build basic login flow
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = () => {
    setClaims(undefined);
    setToken("");
    Cookies.remove("_auth");
  };

  const isValid = () => {
    // We check token validity in an order of operations that minimizes rejection time.
    // In this way, the user gets the fastest experience possible to re-authenticate.
    //   - If there are no JWT credentials set, then the authentication state is invalid
    //   - If the JWT credential has expired, then the authentication state is invalid
    //   - If the JWT credential signature is forged, then the authentication state is invalid
    console.debug("Checking authentication token validity")
    if (claims === undefined) { console.debug("No authentication token exists"); return false }
    if (claims.exp < (Date.now() / 1000)) { console.debug("Authentication token expired"); return false }
    if (verifyAuthToken?.data === null || verifyAuthToken?.data === undefined || verifyAuthToken?.data?.valid === false) {
      console.debug("Authentication token signature is invalid or request failed.");
      return false;
    }
    console.debug("Authentication token is valid.")
    return true
  }

  return (
    <AuthContext.Provider value={{ token, claims, loginSSO, loginAction, logOut, isValid }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, APIEndpoint, transformString };
