import { createContext, useState, ReactNode, Dispatch, SetStateAction } from "react";

interface NavigationContextType {
  tab: number;
  setTab: (tab: number) => Dispatch<SetStateAction<number>> | void;
}

const defaultNavigationState: NavigationContextType = { tab: 0, setTab: () => { } }

const NavigationContext = createContext(defaultNavigationState);

const NavigationProvider = ({ children }: { children?: ReactNode }) => {
  const [tab, setTab] = useState(parseInt(localStorage.getItem('currentTab') || '0'));

  return (
    <NavigationContext.Provider value={{ tab, setTab }}>
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContext, NavigationProvider };
