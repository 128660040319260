import React, { FC, useState, useCallback, useContext } from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import Topnav from '../topnav/topnav';
import Navigation from '../navigation/navigation';
import { NavigationContext } from '../../hooks/navigation_provider';

// Import the logo images
import resyLogo from '../../assets/resy-logo.png';
import uberLogo from '../../assets/uber-logo.png';

interface IntegrationProps {
  name: string;
  logo: string;
  isConnected: boolean;
  onConnect: () => Promise<void>;
  onDisconnect: () => Promise<void>;
}

const Integration: FC<IntegrationProps> = ({ name, logo, isConnected, onConnect, onDisconnect }) => {
  const [loading, setLoading] = useState(false);

  const handleToggle = useCallback(async () => {
    setLoading(true);
    try {
      if (isConnected) {
        await onDisconnect();
      } else {
        await onConnect();
      }
    } catch (error) {
      console.error(`Error toggling ${name} integration:`, error);
    } finally {
      setLoading(false);
    }
  }, [isConnected, name, onConnect, onDisconnect]);

  return (
    <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt={`${name} logo`} style={{ width: 40, height: 40, marginRight: 16 }} />
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Button
        variant="contained"
        onClick={handleToggle}
        disabled={loading}
        color={isConnected ? 'secondary' : 'primary'}
      >
        {loading ? 'Loading...' : isConnected ? 'Disconnect' : 'Connect'}
      </Button>
    </Paper>
  );
};

interface IntegrationsProps { }

const Integrations: FC<IntegrationsProps> = () => {
  const nav = useContext(NavigationContext);
  const integrations = [
    {
      name: 'Resy',
      logo: resyLogo,
      isConnected: false,
      onConnect: async () => { /* Implement Resy connection logic */ },
      onDisconnect: async () => { /* Implement Resy disconnection logic */ },
    },
    {
      name: 'Uber',
      logo: uberLogo,
      isConnected: true,
      onConnect: async () => { /* Implement Uber connection logic */ },
      onDisconnect: async () => { /* Implement Uber disconnection logic */ },
    },
    // Add more integrations as needed
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 8, pb: 7 }}>
        <Box sx={{ p: 3 }} data-testid="Integrations">
          <Typography variant="h4" gutterBottom>Integrations</Typography>
          <Grid container spacing={2}>
            {integrations.map((integration) => (
              <Grid item xs={12} key={integration.name}>
                <Integration {...integration} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Integrations;
