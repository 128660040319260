import { AuthContext } from "./hooks/auth_provider";
import { Navigate, createBrowserRouter, redirect } from "react-router-dom";
import { NavigationProvider } from './hooks/navigation_provider';
import { NavigationContext } from './hooks/navigation_provider';
import { ReactNode, useContext, useEffect } from "react";
import Dashboard from "./components/dashboard/dashboard.lazy";
import Discovery from "./components/discovery/discovery.lazy";
import Login from './components/login/login.lazy';
import Planner from "./components/planner/planner.lazy";
import Signup from "./components/signup/signup.lazy";
import Settings from "./components/account/settings";
import Integrations from "./components/account/integrations";
import Navigation from "./components/navigation/navigation.lazy";
import Topnav from "./components/topnav/topnav.lazy";

const ProtectedRoute = ({ component }: { component?: ReactNode }) => {
  const auth = useContext(AuthContext);
  const nav = useContext(NavigationContext);

  useEffect(() => {
    if (!auth.isValid()) redirect("/login")
  }, [auth])

  return (
    <NavigationProvider>
      <Topnav></Topnav>
      {component}
      <Navigation />
    </NavigationProvider>
  )
}

export const Router = createBrowserRouter([
  { path: "/", element: <Navigate to="/login" /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/dashboard", element: <ProtectedRoute component={<Dashboard />} /> },
  { path: "/discovery", element: <ProtectedRoute component={<Discovery />} /> },
  { path: "/plan", element: <ProtectedRoute component={<Planner />} /> },
  { path: "/settings", element: <ProtectedRoute component={<Settings />} /> },
  { path: "/integrations", element: <ProtectedRoute component={<Integrations />} /> },
]);
