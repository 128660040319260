import React, { lazy, Suspense } from 'react';

const LazyTopnav = lazy(() => import('./topnav'));

const Topnav = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyTopnav {...props} />
  </Suspense>
);

export default Topnav;
