import './App.css';
import { RouterProvider } from "react-router-dom";
import { Router } from './routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './hooks/auth_provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1800
      }
    }
  })
}

export default function App() {
  return (
    <QueryClientProvider client={createQueryClient()}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}>
        <AuthProvider>
          <div className="App">
            <header className="App-header">
              <RouterProvider router={Router} />
            </header>
          </div>
        </AuthProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}
